/*                                                                                                       */
@import "@otto-ec/global-frontend-settings/all";
@import "../imports/own_frontend_settings/breakpoint";

.sp_hideWhenNotLarge {
    display: none;
    @media (min-width: $breakpoint-l) {
        display: inline-block;
    }
}

.sp_hideWhenLarge {
    display: inline-block;
    @media (min-width: $breakpoint-l) {
        display: none;
    }
}
