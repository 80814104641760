@import "@otto-ec/global-frontend-settings/all";
@import "../imports/own_frontend_settings/grid";
@import "../imports/own_frontend_settings/breakpoint";

.shoppagesFragment {
    overflow: hidden;
}

/*                                                                          */
/*                                                                       */
/*                                                                          */
/*                     */
.pl_sheet__content {
  .pl_accordion__content.sp_plainText {
    img {
      max-width: 100%; /*                                                    */
    }
  }

  .pl_accordion__content {
    .sp_textBlockContent {
      img {
        max-width: 100%;
      }
    }
  }
}

.sp_contentHeadline h1 {
    margin-bottom: 2 * $grid-padding;
    padding-left: $grid-padding;
    padding-right: $grid-padding;
}

.sp_link {
    text-decoration: none;
}

.sp_link_icon_arrow_right:after {
    content: "\0000a0>";
    display: inline-block;
    font-family: $iconsFontFamily;
    font-size: 0.75em;
    font-style: normal;
}

.sp_imgLeft {
    float: left;
    margin-right: 2 * $grid-padding;
    max-width: 100%;
}

.sp_imgRight {
    float: right;
    margin-left: 2 * $grid-padding;
    max-width: 100%;
}

.sp_plainText {

    ul,
    ol {
        list-style-position: outside;

        /*                                                       */
        li {

            p:first-child {
                display: inline;
            }

            p:nth-child(2) {
                margin-top: 10px;
            }
        }
    }

    /**
 */
    td {
        /*                                                          */
        word-break: break-word;
        @media (min-width: $breakpoint-l) {
            word-break: normal;
        }
    }

    th {
        /*                                                          */
        word-break: break-word;
        @media (min-width: $breakpoint-l) {
            word-break: normal;
        }
    }
}

.pl_banner.sp_banner {
  & > * {
    flex: 1 1 auto;
    padding-left: 12px;
    padding-bottom: 4px;
    padding-top: 12px;

    &:last-child {
      padding-right: 12px;
    }
  }
}
