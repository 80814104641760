@import "@otto-ec/global-frontend-settings/all";
@import "../imports/own_frontend_settings/breakpoint";

.sp_uni50 {

  .sp_uni50_footer_container {
    @media (min-width: $breakpoint-l) {
      height: 120px;
    }
  }

  .promo-card-image-4-3-s, .promo-card-image-8-3-xl {
    background-size: cover;
  }

  .promo-card-image-4-3-s {
    aspect-ratio: 4/3;
  }

  .promo-card-image-8-3-xl {
    aspect-ratio: 8/3;
  }
}

.pl_grid-col-lg-12 {
  .sp_uni50 {
    .sp_uni50_footer_container {
      height: auto;
    }

    .promo-card-image-4-3-s {
      @media (min-width: $breakpoint-l) {
        display: none
      }
    }

    .promo-card-image-8-3-xl {
      @media not (min-width: $breakpoint-l) {
        display: none
      }
    }
  }
}

.pl_grid-col-lg-6, .pl_grid-col-lg-4, .pl_grid-col-lg-3 {
  .sp_uni50 {
    .promo-card-image-8-3-xl {
      display: none
    }
  }
}
