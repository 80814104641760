@import "@otto-ec/global-frontend-settings/all";
@import "../imports/own_frontend_settings/grid";
@import "../imports/own_frontend_settings/breakpoint";

.sp_textBlock[data-legacy] {
    margin-bottom: 2 * $grid-padding;
    overflow: hidden;
    padding-left: $grid-padding;
    padding-right: $grid-padding;
    width: 100%;

    img {
        max-width: 100%;
    }

    /*                                                                                             */
    &.empty {
        margin-bottom: 0;
    }

    .sp_refSymbol {
        background-color: $black50;
        border-radius: 50%;
        color: $white;
        display: inline-block;
        font-size: 11px;
        height: 18px;
        line-height: 18px;
        text-align: center;
        width: 18px;
    }

    .sp_headline150_red {
        color: $red100;
        margin-bottom: 10px;
    }

    .p_link--1st i {
      text-decoration: underline;
      font-family: 'OttoSans';
      font-size: inherit;
      font-style: italic;
    }
}

.sp_textBlock {
  a {
    word-wrap: break-word;
  }

  img {
    max-width: 100%;
  }

  .p_link--1st i {
    text-decoration: underline;
    font-family: 'OttoSans';
    font-size: inherit;
    font-style: italic;
  }
}

.sp_textBlockWithImage {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  .sp_textBlockImage {
    width: 100%;
    img {
      width: 100%;
    }
  }

  .sp_textBlockContent {
    width: 100%;
    align-self: center;
  }
}

.sp_content .sp_textBlockWithImage {

  @media not (min-width: $breakpoint-l) {
    .sp_textBlockNoText {
      display: none;
    }
  }

  @media (min-width: $breakpoint-l) {
    &.sp_textBlockWithImageRight {
      flex-direction: row-reverse;
    }
  }

  @media (min-width: $breakpoint-l) {
    .sp_textBlockContent, .sp_textBlockImage {
      flex: 1;
      width: 50%;
    }
  }

  &.sp_textBlockWithImageTop {
    .sp_textBlockContent, .sp_textBlockImage {
      flex: 0 1 auto;
      width: 100%;
    }
    .sp_textBlockNoText {
      display: none;
    }
  }
}
