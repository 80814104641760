/**/
/*                    */
/*                */
/**/

/*            */
$font75: (
  font-size: 12px,
  line-height: 18px,
);

/*             */
$font100: (
  font-size: 14px,
  line-height: 20px,
);

/*             */
$font125: (
  font-size: 16px,
  line-height: 22px,
);

/*             */
$font150: (
  font-size: 18px,
  line-height: 24px,
);

/*             */
$font175: (
  font-size: 20px,
  line-height: 26px,
);

/*             */
$font200: (
  font-size: 22px,
  line-height: 28px,
);

/*             */
$font225: (
  font-size: 24px,
  line-height: 30px,
);

/*             */
$font250: (
  font-size: 26px,
  line-height: 32px,
);

/*             */
$font275: (
  font-size: 28px,
  line-height: 34px,
);

/*             */
$font300: (
  font-size: 30px,
  line-height: 36px,
);

/*             */
$font325: (
  font-size: 32px,
  line-height: 38px,
);

/*             */
$font350: (
  font-size: 34px,
  line-height: 40px,
);

/*                        */
$fallbackFontFamily: "Arial", "Helvetica", sans-serif;

/*                    */
$sansFontFamily: "OTTOSans", $fallbackFontFamily;

/*                        */
$sansThinFontFamily: "OttoSansThin", $sansFontFamily;

/*                                  */
$iconFontFamily: "OttoIconFonts", $fallbackFontFamily;

/*                     */
$iconsFontFamily: "OttoIcons", $fallbackFontFamily;

/*           */
$black: #000;

/*           */
$white: #fff;

/*            */
$grey25: #f6f6f6;

/*            */
$grey50: #eeeeee;

/*             */
$grey100: #e6e6e6;

/*             */
$grey200: #c4c4c4;

/*             */
$grey300: #9e9e9e;

/*             */
$grey400: #777777;

/*             */
$black50: #333333;

/*              */
$black100: #222222;

/*           */
$red50: #f00020;

/*            */
$red100: #d4021d;

/*            */
$red200: #ba0019;

/*                    */
$disabled-color: #cccccc;

/*                            */
$error-background-color: #f24e63;

/*                      */
$error-font-color: $grey25;

/*                          */
$error-inverted-color: #e6344b;

/*                      */
$error-form-color: #ffbec6;

/*                              */
$warning-background-color: #f4e245;

/*                          */
$warning-border-color: #ffd387;

/*                        */
$warning-font-color: #222222;

/*                            */
$warning-inverted-color: #f1b500;

/*                                 */
$warning-inverted-icon-color: #ffd200;

/*                        */
$warning-form-color: #fff6a6;

/*                           */
$hint-background-color: #318cd7;

/*                     */
$hint-font-color: $grey25;

/*                         */
$hint-inverted-color: #217ac4;

/*                              */
$success-background-color: #71ba24;

/*                        */
$success-font-color: #ffffff;

/*                            */
$success-inverted-color: #69af1f;

/*                        */
$availability-green: #198504;

/*                         */
$availability-orange: #ee9201;

/*                      */
$availability-red: #da2031;

/*                   */
$service-color: #fbb100;

/*                              */
$service-background-color: #fdd87f;
