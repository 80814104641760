@media (max-width:200px) {
    .sp_smartAppBanner {
        display: none!important;;
    }
}

.sp_smartAppBanner {
    color: #fefefe;
    background-color: #222222;
    line-height: 72px;
    min-height: 74px;
    max-height: 74px;
    position: relative;

    .sp_smartAppBannerFirstColumn {
      position: absolute;
      display: flex;
      top: -4px;
      left: 0;
      z-index: 1;
    }

    .sp_smartAppBannerSecondColumn {
      position: absolute;
      top: -4px;
      right: 0;
    }

    .sp_smartAppBannerItem {
        font-size: .875rem;
        vertical-align: middle;
    }

    .sp_smartAppBannerClose {
      color: #aaaaaa;
      font-size: 28px;
      font-weight: bold;
      margin: 4px 4px 0 8px;
    }

    .sp_smartAppBannerClose:hover,
    .sp_smartAppBannerClose:focus {
        color: #fefefe;
        text-decoration: none;
        cursor: pointer;
    }

    .sp_smartAppBannerGotoAppButton {
      width: auto;
      display: inline-block;
      margin: 16px 8px 16px 4px;
    }

  @media (max-width: 370px) {
    .sp_smartAppBannerText {
      font-size: 0.75rem;
    }

    .sp_smartAppBannerGotoAppButton {
      padding: 0 10px;
    }
  }

  .sp_smartAppBannerAppIcon {
    margin: 16px 0 0 8px;
  }
}
