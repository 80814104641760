@import "@otto-ec/global-frontend-settings/all";
@import "../imports/own_frontend_settings/grid";
@import "../imports/own_frontend_settings/breakpoint";

.sp_teaser:last-of-type {
  margin-bottom: $grid-padding;
}

.sp_teaser {
    margin-top: $grid-padding;
    overflow: hidden;
    padding-left: $grid-padding;
    padding-right: $grid-padding;
    position: relative;
    width: 100%;

    .sp_teaserImage {
        background-size: cover;
        height: 0;
        margin-bottom: $grid-padding;
        width: 100%;
        @media (min-width: $breakpoint-l) {
            margin-bottom: 0;
        }
    }

    .sp_teaserTransparencyLayerWrapper {
        width: 50%;
        height: 100%;
        position: absolute;
        right: $grid-padding;
        top: 0;

        .sp_teaserTransparencyLayer {
            display: none;
            height: 100%;
            margin-left: 2 * $grid-padding;
            @media (min-width: $breakpoint-l) {
                display: block;
                opacity: 0.75;
            }
        }
    }

    .sp_teaserContentWrapper {
        width: 100%;
        bottom: 0;
        position: relative;
        right: 0;
        @media (min-width: $breakpoint-l) {
            position: absolute;
            right: $grid-padding;
        }

        .sp_teaserContent {
            margin-bottom: $grid-padding;
            padding-left: 0;
            padding-right: 0;
            @media (min-width: $breakpoint-l) {
                padding-left: 2 * $grid-padding;
                padding-right: 2 * $grid-padding;
            }
        }
    }

    .sp_teaserLine {
        margin-bottom: $grid-padding;
    }
}

.sp_teaserWhite .sp_teaserTransparencyLayer {
    background-color: $white;
}

.sp_teaserBlack {
    color: inherit;
    @media (min-width: $breakpoint-l) {
        color: $white;
    }

    .sp_teaserTransparencyLayer {
        background-color: $black;
    }
}

.contentWithSidebar .sp_teaser {

    .sp_teaserImage {
        @media (min-width: $breakpoint-l) {
            background-position: center;
        }
        @media (min-width: $breakpoint-xl) {
            height: 0;
        }
    }

    .sp_teaserContentWrapper {
        @media (min-width: $breakpoint-l) {
          width: calc(100% / (12 - $left-navigation-cols) * 4);
        }
    }
}

.contentFullWidth .sp_teaser .sp_teaserContentWrapper {
    @media (min-width: $breakpoint-l) {
        width: calc(100% / 12 * 5);
    }
}
